import BurgerButton from "./BurgerButton";
import Logo from "./Logo";
import Navigation from "./Navigation";
import "../../styles/navigation/Navbar.css";
import Wrapper from "../common/Wrapper";
import { getMenuItems } from "../../services/menu/menuService";
import { useRef, useState } from "react";

export default function Navbar() {
  const navigationRef = useRef();
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const className = isBurgerActive ? "header sticky" : "header";
  const handleBurgerButtonClick = () => {
    navigationRef.current.classList.toggle("active");
    setIsBurgerActive(!isBurgerActive);
  };

  return (
    <header className={className}>
      <Wrapper className="menu">
        <Logo />
        <Navigation
          items={getMenuItems()}
          refItem={navigationRef}
          isBurgerActive={isBurgerActive}
          onLinkClick={handleBurgerButtonClick}
        />
        <BurgerButton onClick={handleBurgerButtonClick} />
      </Wrapper>
    </header>
  );
}
