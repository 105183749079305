import { Link } from "react-router-dom";
import SubNavigation from "./SubNavigation";
import { useState } from "react";

export default function ExpandableNavItem({
  className,
  title,
  linkClassName,
  items,
  isBurgerActive,
  onLinkClick
}) {
  const [isSubNavigationAvailable, setIsSubNavigationAvailable] = useState(false);

  const handleClick = () => {
    if (isBurgerActive) {
      setIsSubNavigationAvailable(!isSubNavigationAvailable);
    }
  }
  const iconClass = `fa ${isSubNavigationAvailable ? 'fa-caret-square-o-up' : 'fa-caret-square-o-down'} nav-item-icon`
  return (
    <li
      className={className}
      onMouseEnter={() => setIsSubNavigationAvailable(true)}
      onMouseLeave={() => setIsSubNavigationAvailable(false)}
      onClick={handleClick}
    >
      <Link className={linkClassName}>{title}</Link>
      <i className={iconClass} />
      <SubNavigation isAvailable={isSubNavigationAvailable} items={items} onLinkClick={onLinkClick}/>
    </li>
  );
}
