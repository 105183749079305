import GenericCard from "./GenericCard";
import { Link } from "react-router-dom";

export default function MaterialCard({ item }) {
  return (
    <GenericCard item={item}>
      <Link to={`${item.id}`}>Читать</Link>
    </GenericCard>
  );
}
