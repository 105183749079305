import { useParams } from "react-router-dom";
import { getDataItem } from "../../services/data/dataService";
import MaterialPage from "./MaterialPage";
import TestVariantsPage from "./TestVariantsPage";
import TestPage from "./TestPage";
import { getTest } from "../../services/tests/testsService";
import PresentationPage from "./PresentationPage";

export default function Page() {
  const { caregory, type, id, variantId } = useParams();  
  const item = getDataItem(caregory, type, Number(id));

  return getPage(type, item, variantId);
}

function getPage(type, item, variantId) {
  if (variantId) {
    const test = getTest(item.id, Number(variantId));
    return <TestPage id={item.id} item={test} />
  }

  switch (type) {
    case "materials":
      return <MaterialPage html={item.html} />;
    case "tests":
      return <TestVariantsPage item={item} />;
    case "presentations":
      return <PresentationPage id={item.id} />
    default:
      return <div>"Page not found!"</div>;
  }
}
