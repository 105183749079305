import { getPresentation } from '../../services/presentations/presentationsService';
import '../../styles/page/PresentationPage.css';

export default function PresentationPage({id}) {
  const src = `${window.location.origin}${getPresentation(id)}`;

  return (
    <div className="presentation">
      <iframe title="presentation" src={`https://docs.google.com/gview?url=${src}&embedded=true`} frameborder="0" width="100%" height="100%" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
    </div>
  );
}


