import NotExpandableNavItem from "./NotExpandableNavItem";
import ExpandableNavItem from "./ExpandableNavItem";

export default function Navigation({ items, refItem, isBurgerActive, onLinkClick }) {
  const handleLinkClick = () => {
    if (isBurgerActive) {
      onLinkClick();
    }
  }

  const listItems = items.map((item) =>
    item.isExpandable ? (
      <ExpandableNavItem
        className="nav-item"
        title={item.title}
        linkClassName="nav-link"
        items={item.subMenuItems}
        isBurgerActive={isBurgerActive}
        onLinkClick={handleLinkClick}
      />
    ) : (
      <NotExpandableNavItem
        className="nav-item"
        title={item.title}
        linkClassName="nav-link"
        to={item.route}
        onLinkClick={handleLinkClick}
      />
    )
  );

  return (
    <nav ref={refItem} className="nav">
      <ul className="nav-items">
        {listItems}
      </ul>
    </nav>
  );
}
