import { useParams } from "react-router-dom";
import { getDataItems } from "../../services/data/dataService";
import Card from "../card/Card";
import '../../styles/cards/Cards.css';

export default function Cards() {
  const { caregory, type } = useParams();
  const items = getDataItems(caregory, type)?.map((item) => (
    <Card item={item} />
  ));
  return <div className="cards">{items}</div>;
}
