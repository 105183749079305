import data from "./tests.json";
import results from "./results.json";

export function getTest(id, variantId) {
  return data.tests
    .find((test) => test.id === id)
    .variants.find((variant) => variant.id === variantId);
}

export function getTestVariants(id) {
  return data.tests.find((test) => test.id === id)?.variants;
}

export function getTestResult(id, variantId, answers) {
  const test = results.tests.find(
    (test) => test.id === id && test.variant === variantId
  );
  const correctQuestions = test.questions.filter(
    (question) => getArrayString(question) === getAnswerVariantIdsString(answers, question)
  );
  return {
    totalQuestions: test.questions.length,
    result: correctQuestions.length,
  };
}

function getArrayString(question) {
  const str = question.variantIds.sort().toString();
  return str;
}

function getAnswerVariantIdsString(answers, question) {
  const answer = answers.find((x) => x.questionId === question.id);
  const str = answer.variantIds.sort().toString();
  return str;
}
