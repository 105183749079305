import CardButton from "./buttons/CardButton";

export default function GenericCard({ item, children }) {
  return (
    <>
      <div>{item.title}</div>
      <CardButton>{children}</CardButton>
    </>
  );
}
