import { Link } from "react-router-dom";

export default function NotExpandableNavItem({
  className,
  title,
  linkClassName,
  to,
  onLinkClick,
}) {
  return (
    <li className={className}>
      <Link to={to} className={linkClassName} onClick={onLinkClick}>
        {title}
      </Link>
    </li>
  );
}
