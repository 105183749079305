import { getTestResult } from "../../services/tests/testsService";
import "../../styles/card/Card.css";

export default function ResultCard({ id, variantId, answers }) {
  const result = getTestResult(id, variantId, answers);

  return (
    <div className="card result-card">
      <h3>
        Количество правильных ответов {result.result} из {result.totalQuestions}
      </h3>
    </div>
  );
}
