import "../../styles/cards/TestPageCard.css";

export default function TestPageCard({
  question,
  onNextClick,
  onPreviousClick,
  onResultClick,
  answers,
  onAnswersChange,
  isRusltActive,
}) {
  const inputType = question.isMultiChoice ? "checkbox" : "radio";
  const items = question?.variants.map((variant) => (
    <li>
      <input
          type={inputType}
          key={variant.variant}
          id={variant.id}
          name={question.id}
          value={variant.variant}
          checked={answers.find(
            (answer) =>
              answer?.questionId === question?.id &&
              answer?.variantIds.includes(variant.id)
          )}
          onChange={onAnswersChange}
        />
      <label for={variant.id}>{variant.variant}</label>
    </li>
  ));
  return (
    <>
      <div className="testCard">
        <h4>
          {question.id}. {question?.question}
        </h4>
        <ul className="variants">{items}</ul>
        <div className="buttons">
          <div className="button" onClick={onPreviousClick}>
            Предыдущий
          </div>
          <div className="button" onClick={onNextClick}>
            Следующий
          </div>
        </div>
      </div>
      <div
        className={`button result-btn${
          isRusltActive ? "" : " result-btn-disabled"
        }`}
        onClick={isRusltActive ? onResultClick : undefined}
      >
        Проверить
      </div>
    </>
  );
}
