import "../styles/App.css";
import Navbar from "./navigation/Navbar";
import { Routes, Route } from "react-router-dom";
import About from "./about/About";
import Cards from "./cards/Cards";
import Page from "./page/Page";
import ArchivPage from "./page/ArchivPage";

function App() {
  return (
    <div className="container">
      <Navbar />
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/belarushistory/arhiv"
          element={<ArchivPage category="belarushistory" />}
        />
        <Route path="/:caregory/:type" element={<Cards />} />
        <Route path="/:caregory/:type/:id/:variantId?" element={<Page />} />
      </Routes>
    </div>
  );
}

export default App;
