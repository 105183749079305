import { useState } from "react";
import TestPageCard from "../card/TestPageCard";
import ResultCard from "../card/ResultCard";

export default function TestPage({ id, item }) {
  const [answers, setAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [isResult, setIsResult] = useState(false);
  const question = item.questions.find(
    (question) => question.id === currentQuestion
  );
  function handleAnswerChange(e) {
    const variantId = Number(e.target.id);
    const answer = answers?.find(
      (answer) =>
        answer?.questionId === question?.id && variantId !== answer.variantId
    );
    if (!answer) {
      setAnswers([
        ...answers,
        { questionId: question?.id, variantIds: [variantId] },
      ]);
      return;
    }

    const { checked, type } = e.target;

    const changed = answers.map((answer) => {
      if (checked && answer?.questionId === question?.id) {
        const variantIds =
          type === "radio" ? [variantId] : [...answer.variantIds, variantId];
        return { questionId: question?.id, variantIds: variantIds };
      } else if (!checked && answer?.questionId === question?.id) {
        return {
          questionId: question?.id,
          variantIds: answer.variantIds.filter((vrId) => vrId !== variantId),
        };
      }

      return answer;
    });

    setAnswers(changed);
  }

  function handlePreviousClick() {
    if (currentQuestion - 1 >= 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
  }

  function handleNextClick() {
    if (currentQuestion + 1 <= item.questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    }
  }

  function handleResultClick() {
    if (answers.length === item.questions.length) {
      setIsResult(true);
    }
  }

  return (
    <>
      {isResult ? (
        <ResultCard id={id} variantId={item.id} answers={answers} />
      ) : (
        <div className="test-container">
          <TestPageCard
            question={question}
            onNextClick={handleNextClick}
            onPreviousClick={handlePreviousClick}
            answers={answers}
            onAnswersChange={handleAnswerChange}
            onResultClick={handleResultClick}
            isRusltActive={answers.length === item.questions.length}
          />
        </div>
      )}
    </>
  );
}
