import data from "./data.json";

export function getDataItems(category, type) {
  return data.items
    .find((item) => item.category === getCategory(category))
    ?.items.filter((item) => item.type === getType(type));
}

export function getDataItem(category, type, id) {
  return data.items
    .find((item) => item.category === getCategory(category))
    ?.items.find((item) => item.type === getType(type) && item.id === id);
}

function getCategory(category) {
  switch (category) {
    case "history":
      return 1;
    case "belarushistory":
      return 2;
    case "geography":
      return 3;
    default:
      return 0;
  }
}

function getType(type) {
  switch (type) {
    case "films":
      return 1;
    case "materials":
      return 2;
    case "tests":
      return 3;
    case "presentations":
      return 4;
    case "conspects":
      return 5;
    case "arhiv":
      return 6;
    default:
      return 0;
  }
}
