import GenericCard from "../card/GenericCard";
import { getTestVariants } from "../../services/tests/testsService";
import { Link } from "react-router-dom";
import "../../styles/cards/Cards.css";
import "../../styles/card/Card.css";

export default function TestVariantsPage({ item }) {
  const items = getTestVariants(item.id)?.map((variant) => (
    <div className="card">
      <GenericCard item={variant}>
        <Link to={`${variant.id}`}>Начать</Link>
      </GenericCard>
    </div>
  ));

  return <div className="cards">{items}</div>;
}
