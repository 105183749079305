import NotExpandableNavItem from "./NotExpandableNavItem";

export default function SubNavigation({isAvailable, items, onLinkClick}) {
  const subNavClassName = isAvailable ? "sub-nav" : "sub-nav-hidden";
  const listItems = items.map(item => <NotExpandableNavItem to={item.route} className="nav-item" title={item.title} linkClassName="sub-nav-link" onLinkClick={onLinkClick} />)
  return (
    <div className={subNavClassName}>
      <ul className="sub-nav-items">
        {listItems}
      </ul>
    </div>
  );
}
