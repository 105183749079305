import { getDataItems } from "../../services/data/dataService";
import '../../styles/page/ArhivPage.css';

export default function ArchivPage({ category }) {
  const items = getDataItems(category, "arhiv");
  return (
    <div className="arhiv">
      {items.map((item) => (
        <div className="arhiv-item">
          <img key={item.id} src={item.src} alt={`${item.id}`}/>
        </div>
      ))}
    </div>
  );
}
