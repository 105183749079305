import "../../styles/card/Card.css";
import TestCard from "./TestCard";
import FilmCard from "./FilmCard";
import MaterialCard from "./MaterialCard";
import PresentationCard from "./PresentationCard";

export default function Card({ item }) {
  const getCard = (itm) => {
    switch (itm.type) {
      case 1:
        return <FilmCard item={itm} />;
      case 2:
        return <MaterialCard item={itm} />;
      case 3:
        return <TestCard item={itm} />;
      case 4:
        return <PresentationCard item={itm} />;
      default:
        return <h1>Not supported type!!!</h1>;
    }
  };

  const card = getCard(item);
  return <div className="card">{card}</div>;
}
