import "../../styles/about/About.css";
import img2 from "../../images/about/img2.jpg";

export default function About() {
  return (
    <div className="container-about">
      <div className="about-top">
        <p>
          Персональный сайт преподавателя истории и географии Слабодчик Юлии
          Михайловны
        </p>
        <p>Здравствуйте, уважаемые посетители моего сайта!</p>
        <p className="about-item">
          Вас приветствует преподаватель учреждения образования «Гомельский
          государственный химико-технологический колледж» Слабодчик Юлия
          Михайловна.
        </p>
      </div>
      <div className="about-content">
        <div className="about-img-container">
        <img
          className="about-img"
          // height={500}
          // width={400}
          src={img2}
          alt="Юлия Слабодчик Михайловна(фото)"
        />
        </div>
        <p>
          Сайт создан для того, чтобы сделать изучение истории и географии более
          увлекательным процессом, а также с целью обобщения и систематизации
          накопленного материала по всемирной истории, истории Беларуси и
          географии. На сайте размещены материалы педагогического опыта,
          тестовые задания, Online-тесты для контроля знаний, опорные конспекты
          по темам учебной программы, архивные документы, интересные факты,
          мультимедийные презентации, фильмы и исследовательские работы.
        </p>
        <h3>Обо мне</h3>
        <p>Слабодчик Юлия Михайловна.</p>
        <p>Образование: высшее</p>
        <p>
          Родилась 12 января 1986 г. Детство и юность провела в п. Новая Гута,
          Гомельского района, где окончила среднюю школу.
        </p>
        <p>
          С 2001 по 2005 г. обучалась в УО «Гомельский музыкально-педагогический
          колледж им. Выготского» по специальности «Педагогика и психология
          дошкольная».
        </p>
        <p>
          Присвоена квалификация «Воспитатель дошкольного учреждения.
          Руководитель кружка (студии) ритмики и хореографии».
        </p>
        <p>
          С 2011 по 2017 г. обучалась в УО «Гомельский государственный
          университет имени Франциска Скорины», по специальности «История
          (отечественная и всеобщая)», присвоена квалификация: Историк.
          Преподаватель истории и социально-гуманитарных дисциплин. Обучаясь в
          университете, принимала участие в конференциях, на которых выступала с
          научными статьями, посвященными Старообрядчеству на Беларуси,
          Православной церкви и Системе образования.
        </p>
        <p>
          Начала свою трудовую деятельность после окончания УО «Гомельского
          музыкально- педагогического колледжа им. Выготского». В 2005 г.
          принята на должность воспитателя Новогутского Ясли /сада.
        </p>
        <p>
          С 2007 г. принята в УО «ГГПТЛ железнодорожного транспорта», на
          должность педагога- организатора и руководителя кружка ритмики и
          хореографии.
        </p>
        <p>
          В 2015 г. принята на должность мастера производственного обучения в УО
          «Гомельский государственный профессиональный технологический лицей».
        </p>
        <p>
          В 2019 г. принята на должность преподавателя общеобразовательных
          предметов «история» и «география» в УО «Гомельский государственный
          профессиональный технологический лицей».
        </p>
        <h3>Жизненное кредо.</h3>
        <p>
          «Мир может стать чуточку лучше, Если вы сыграли важную роль в жизни
          какого-нибудь ребёнка»
        </p>
      </div>
    </div>
  );
}
