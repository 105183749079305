import GenericCard from "./GenericCard";

export default function FilmCard({ item }) {
  return (
    <GenericCard item={item}>
      <a href={item.link} target="_blank" rel="noreferrer noopener">
        Смотреть
      </a>
    </GenericCard>
  );
}
